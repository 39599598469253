import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'gatsby-plugin-react-i18next';
import BaseLayout from '../components/layouts/base-layout';
import {  Container } from 'react-bootstrap';
import HeaderLayout from '../components/layouts/header-layout';
import '../styles/template.scss';
import { StaticImage } from 'gatsby-plugin-image';


type TemplateProps = {
  data: {
    markdownRemark: {
      html: any;
      frontmatter: any;
    };
    locales: any;
  };
};
const Template = (props: TemplateProps) => {

  const { frontmatter, html } = props.data.markdownRemark;

  return (
    <BaseLayout>
      <Helmet title={frontmatter.title} />
      <HeaderLayout isMdPage></HeaderLayout>
      <Container  >
        <div className={'aboutContainer'}>
          {frontmatter.slug ==='/about' && 
             <div className={'aboutImage'}><StaticImage src={'../images/about.png'} alt={''}></StaticImage></div>
          }
          <div style={{ marginBottom: '3.75rem' }} className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }}></div> 
         
        </div>
       
      
      </Container>
    </BaseLayout>
  );
};

export default Template;

export const query = graphql`
  query ($language: String!, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(frontmatter: {slug: {eq: $slug}, lang: {eq: $language}}) {
      html
      frontmatter {
        slug
        title
        description
        h1
      }
    }
  }
`;
